import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import snakeCase from 'lodash/snakeCase';

import { addPromoToCarousel as addPromoToCarouselAction } from '../schema/actions';
import EpisodeInfo from '../generic/EpisodeInfo';
import RecipeInfo from './RecipeInfo';
import Image from '../generic/Image';
import { promoShape } from './shapes';

if (process.env.CLIENT) {
  require('./Promo.scss'); // eslint-disable-line global-require
}

export class Promo extends Component {
  constructor(props) {
    super(props);
    const { id, title, url } = this.props;
    this.props.addPromoToCarousel({ id, title, url });
  }

  render() {
    const {
      extraClasses,
      duration,
      episodeNumber,
      imageId,
      label,
      labelClassName,
      playIconClass,
      seriesNumber,
      synopsis,
      synopsisClassName,
      subtitle,
      title,
      titleClassName,
      type,
      url,
      servingsText,
      prepTime,
      cookingTime,
      standard,
    } = this.props;

    const noImage = isEmpty(imageId);

    return (
      <a
        className={classNames('promo', type && `promo__${snakeCase(type)}`, extraClasses.promo)}
        href={url}
      >
        <div className="gel-layout gel-layout--flush promo__text__wrap">
          {imageId && (
            <div
              className={classNames(
                'promo__image-container',
                'gel-layout__item',
                extraClasses.image
              )}
            >
              <Image className="promo__image" src={imageId} />
              {label && <div className={classNames('promo__label', labelClassName)}>{label}</div>}
              {playIconClass && (
                <div className="promo__play gel-long-primer">
                  <div className={classNames('promo__play-icon', playIconClass)} />
                  {duration && <span className="promo__play-text gel-long-primer">{duration}</span>}
                </div>
              )}
            </div>
          )}
          <div
            className={classNames(
              'gel-layout__item',
              'promo__text',
              { 'promo__text--bottom-padding': type },
              extraClasses.content
            )}
          >
            <div className="gel-wrap">
              <div className="gel-layout">
                <div className="gel-layout__item promo__title-container">
                  <h3
                    className={classNames(
                      'promo__title',
                      noImage ? 'promo__title-no-image gel-double-pica' : titleClassName
                    )}
                  >
                    {title}
                  </h3>
                  {episodeNumber && (
                    <EpisodeInfo
                      className="gel-long-primer"
                      episodeNumber={episodeNumber}
                      seriesNumber={seriesNumber}
                    />
                  )}
                  {subtitle && <span className="promo__subtitle gel-long-primer">{subtitle}</span>}
                  {synopsis && (
                    <p className={classNames('promo__synopsis', synopsisClassName)}>{synopsis}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames({ 'promo__recipe-info-container': !standard })}>
          {type && (
            <span
              className={classNames(
                'promo__type gel-minion',
                { 'no-image': noImage, 'promo__type__recipe-info': !standard },
                extraClasses.type
              )}
            >
              {type}
            </span>
          )}
          {!standard && (
            <RecipeInfo servingsText={servingsText} prepTime={prepTime} cookingTime={cookingTime} />
          )}
        </div>
      </a>
    );
  }
}

Promo.propTypes = promoShape;

Promo.defaultProps = {
  extraClasses: {},
  collectionId: '',
  collectionTitle: '',
  titleClassName: 'gel-pica',
  label: undefined,
  labelClassName: 'gel-minion',
  playIconClass: undefined,
  synopsisClassName: 'gel-long-primer',
  servingsText: '',
  prepTime: '',
  cookingTime: '',
  episodeNumber: undefined,
  seriesNumber: undefined,
  standard: true,
};

/* eslint-disable */
export default connect(state => state, {
  addPromoToCarousel: addPromoToCarouselAction,
})(Promo);
/* eslint-enable */

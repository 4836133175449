import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContentSeparator from '../generic/ContentSeparator';

export default function RecipeInfo({ servingsText, prepTime, cookingTime }) {
  return (
    <>
      <ContentSeparator />
      <div className={classNames('promo__recipe-info')}>
        {servingsText && (
          <p className={classNames('gel-long-primer', 'promo__recipe-info__serving-size')}>
            {servingsText}
          </p>
        )}
        {prepTime && (
          <p className={classNames('gel-long-primer', 'promo__recipe-info__prep-time')}>
            <span className="gel-long-primer-bold">Prep: </span>
            {prepTime}
          </p>
        )}
        {cookingTime && (
          <p className="gel-long-primer">
            <span className="gel-long-primer-bold">Cook:</span> {cookingTime}
          </p>
        )}
      </div>
    </>
  );
}

RecipeInfo.defaultProps = {
  servingsText: '',
  prepTime: '',
  cookingTime: '',
};

RecipeInfo.propTypes = {
  servingsText: PropTypes.string,
  prepTime: PropTypes.string,
  cookingTime: PropTypes.string,
};

import { ADD_PRIMARY_IMAGE_URL, ADD_PROMO, ADD_VIDEO, ADD_INDEX_RECIPE } from './constants';

export const addPrimaryImageUrl = primaryImageUrl => ({
  type: ADD_PRIMARY_IMAGE_URL,
  primaryImageUrl,
});

export const addPromoToCarousel = promo => ({
  type: ADD_PROMO,
  promo,
});

export const addVideo = video => ({
  type: ADD_VIDEO,
  video,
});

export const addIndexRecipe = recipe => ({
  type: ADD_INDEX_RECIPE,
  recipe,
});

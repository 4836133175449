import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

if (process.env.CLIENT) {
  require('./EpisodeInfo.scss'); // eslint-disable-line global-require
}

const EpisodeInfo = ({ className, episodeNumber, seriesNumber, tag: Tag, ...props }) => (
  <Tag className={classNames('episode-info', className)} {...props}>
    {seriesNumber && episodeNumber
      ? `Series ${seriesNumber}, Episode ${episodeNumber}`
      : `Episode ${episodeNumber}`}
  </Tag>
);

EpisodeInfo.propTypes = {
  className: PropTypes.string,
  episodeNumber: PropTypes.number.isRequired,
  seriesNumber: PropTypes.number,
  tag: PropTypes.node,
};

EpisodeInfo.defaultProps = {
  className: undefined,
  seriesNumber: undefined,
  tag: 'p',
};

export default EpisodeInfo;
